import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FloatingIcons from './components/FloatingIcons';
import timeless3 from '../img/timeless3.jpg';
import timeless4 from '../img/timeless4.jpg';
import timeless2 from '../img/timeless2.jpg';
import timeless1 from '../img/timeless1.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import timelessLogo from '../img/timeless.png';
// Swiper.js Imports
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';

const About = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    AOS.init({ duration: 1000 });


  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    
  sliderContainer: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    width: '100%',
    height: '75vh',
    overflow: 'hidden',
    gap: '20px', // Adds spacing between sliders
    justifyContent: 'center',
  },
  sliderWrapper: {
    width: isMobile ? '100%' : '48%', // Adjust width to create spacing
    height: '75vh',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '30px',
    borderRadius: '10px', // Optional: Softened edges
    background: 'transparent',
  },
  carouselImage: {
    width: '100%',
    height: '75vh',
    objectFit: 'cover',
    borderRadius: '10px', // Optional: Adds rounded corners for better aesthetics
  },
    stackedImagesContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100vh',
      scrollSnapType: 'y mandatory',
      overflowY: 'scroll',
    },
    stackedImage: {
      width: '100%',
      height: '100vh',
      objectFit: 'cover',
      scrollSnapAlign: 'start',
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
      textAlign: 'center',
      fontFamily: 'Corbel, sans-serif',
      minHeight: '80vh',
      display: 'flex',
      flexDirection: 'column',
    },
    heroSection: {
      backgroundColor: '#f5f5f5',
      padding: '50px 20px',
      borderRadius: '8px',
      marginBottom: '40px',
    },
    heroHeading: {
      fontSize: '3rem',
      fontWeight: 'bold',
      color: '#333',
      margin: '0',
    },
    heroSubtitle: {
      fontSize: '1.5rem',
      color: '#666',
    },
    content: {
      flex: '1',
      padding: '20px',
    },
    overviewSection: {
      marginBottom: '40px',
    },
    missionSection: {
      marginBottom: '40px',
    },
    mobileContainer: {
      display: 'flex',
      flexDirection: 'column', // Stack them vertically
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
    },
    mobileSwiperContainer: {
      position: 'relative',
      width: '100%',
      height: '50vh',
      overflow: 'hidden',
      padding: '20px',
    },

    shadowOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8))',
      zIndex: 2, // Ensures the shadow is above images
      pointerEvents: 'none', // Prevents interference with Swiper
    },

    // mobileImage: {
    //   width: '100%',
    //   height: '100%',
    //   objectFit: 'cover',
    //   zIndex: 1,
    // },


    mobileImage: {
      width: '100vw', // Make sure images take the full width
      height: '50vh', // Make sure images take the full height
      objectFit: 'cover', // Prevent image distortion
    },
    imageShadowOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1,
    },
    logoContainer: {
      position: 'absolute',
      width: '100%', 
      top: '50%', 
      left: '50%', 
      transform: 'translate(-50%, -50%)', 
      zIndex: 10, 
      paddingBottom: '20px', 
      borderRadius: '10px', 
    },

    logoImage: {
      width: '100%',
      height: 'auto',
      filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.7))', 
    },
  };

  return (
    <>
      <Navbar />

      {isMobile ? (
        // **Mobile Version: Stacked Images**
        // <div style={styles.stackedImagesContainer}>
        //   <img src={timeless1} alt="Store 3" style={styles.stackedImage} />
        //   <img src={timeless3} alt="Store 4" style={styles.stackedImage} />
        // </div>
        <div style={styles.mobileContainer}>
          <div style={styles.mobileSwiperContainer}>
         
            <Swiper
              direction="horizontal"
              modules={[Autoplay]}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              loop={true}
              style={{ width: '100%', height: '100vh' }}
            >
              <SwiperSlide>
                <img src={timeless2} alt="Store 3" style={styles.mobileImage} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={timeless4} alt="Store 4" style={styles.mobileImage} />
              </SwiperSlide>
            </Swiper>
          </div>
         
          <div style={styles.mobileSwiperContainer}>
         
            <Swiper
              direction="horizontal"
              modules={[Autoplay]}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              loop={true}
              style={{ width: '100%', height: '100vh' }}
            >
              <SwiperSlide>
                <img src={timeless1} alt="Store 3" style={styles.mobileImage} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={timeless3} alt="Store 4" style={styles.mobileImage} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

      ) : (
        // **Desktop Version: Side-by-Side Swiper Carousels**
        <div style={styles.sliderContainer}>
        {/* Left Swiper */}
        <div style={styles.sliderWrapper}>
        
          <Swiper
            modules={[Autoplay, EffectFade]}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            effect="fade"
            loop={true}
            style={{ width: '100%', height: '100%' }}
          >
            <SwiperSlide>
              <img src={timeless2} alt="Store 3" style={styles.carouselImage} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={timeless4} alt="Store 4" style={styles.carouselImage} />
            </SwiperSlide>
          </Swiper>
        </div>
      
        {/* <div style={styles.logoContainer}>
            <img src={timelessLogo} alt="Timeless Logo" style={styles.logoImage} />
          </div> */}

        {/* Right Swiper */}
        <div style={styles.sliderWrapper}>
       
          <Swiper
            modules={[Autoplay, EffectFade]}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            effect="fade"
            loop={true}
            style={{ width: '100%', height: '100%' }}
          >
            <SwiperSlide>
              <img src={timeless1} alt="Store 1" style={styles.carouselImage} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={timeless3} alt="Store 3" style={styles.carouselImage} />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      
      )}

      <div style={styles.container}>
        <div style={styles.heroSection}>
          <h1 style={styles.heroHeading}>{t('about.heading')}</h1>
          <p style={styles.heroSubtitle}>{t('about.subtitle')}</p>
        </div>
        <div style={styles.content}>
          <section style={styles.overviewSection}>
            <h4 style={styles.sectionHeading}>{t('about.our_story')}</h4>
            <h4 style={styles.sectionText}>{t('about.our_story_text')}</h4>
          </section>
          <section style={styles.missionSection}>
            <h4 style={styles.sectionHeading}>{t('about.mission')}</h4>
            {/* <h4 style={styles.sectionText}>{t('about.mission_text')}</h4> */}
          </section>
        </div>
      </div>

      <FloatingIcons />
      <Footer />
    </>
  );
};

export default About;
