import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"; 
const ContactSpacer = () => <div style={{ height: '100px' }}></div>;

const Services = () => {
    const { t } = useTranslation();
    const [hoverIndex, setHoverIndex] = useState(null);

    const services = [
        {
            title: t('services.sell_watch_title'),
            description: t('services.sell_watch_description'),
        },
        {
            title: t('services.buy_store_title'),
            description: t('services.buy_store_description'),
        },
        {
            title: t('services.consignment_title'),
            description: t('services.consignment_description'),
        },
        {
            title: t('services.personal_shopping_title'),
            description: t('services.personal_shopping_description'),
        },
    ];

    return (
        <>
            <Navbar />
            <div style={styles.container}>
                <ContactSpacer />
                <h1 style={styles.heading}>{t('services.page_title')}</h1>




                <div style={styles.servicesGrid}>
                    {services.map((service, index) => (
                        <div
                            key={index}
                            style={{
                                ...styles.serviceBox,
                                ...(hoverIndex === index ? styles.serviceBoxHover : {}),
                                ...(index % 2 === 0 ? styles.alignLeft : styles.alignRight),
                            }}
                            onMouseEnter={() => setHoverIndex(index)}
                            onMouseLeave={() => setHoverIndex(null)}
                        >
                            <div style={styles.textContent}>
                                <h3 style={styles.serviceTitle}>{service.title}</h3>
                                <p style={styles.serviceDescription}>{service.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Buttons for Contact Actions - Placed Below Services */}
                <div style={styles.buttonContainer}>
    <button style={styles.ctaButton} onClick={() => window.location.href = "mailto:info@timelesswatcheszagreb.com"}>
        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: "8px" }} />
        {t('services.contact_us')}
    </button>
    <button style={styles.whatsappButton} onClick={() => window.open("https://wa.me/+385992810099", "_blank")}>
        <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: "8px" }} />
        {t('services.whatsapp')}
    </button>
    <button style={styles.phoneButton} onClick={() => window.location.href = "tel:+385992810099"}>
        <FontAwesomeIcon icon={faPhone} style={{ marginRight: "8px" }} />
        {t('services.call_us')}
    </button>
</div>


            </div>
            <Footer />
        </>
    );
};

const styles = {
    container: {
        maxWidth: '100%',
        margin: '0 auto',
        padding: '50px 20px',
        textAlign: 'center',
        backgroundColor: '#10381f',
    },
    heading: {
        fontSize: '3rem',
        fontWeight: 'bold',
        marginBottom: '40px',
        color: '#FFFFFF',
        fontFamily: 'Red Rose, sans-serif',
        textTransform: 'uppercase',
        letterSpacing: '2px',
    },
    servicesGrid: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        alignItems: 'center',
    },
    serviceBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        padding: '30px',
        borderRadius: '15px',
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
        textAlign: 'left',
        transition: 'transform 0.4s ease, box-shadow 0.4s ease',
        width: '85%',
        border: '2px solid transparent',
    },
    serviceBoxHover: {
        transform: 'scale(1.02)',
        boxShadow: '0px 12px 30px rgba(0, 0, 0, 0.2)',
        border: '2px solid #10381f',
    },
    textContent: {
        flex: '1',
    },
    serviceTitle: {
        fontSize: '1.6rem',
        fontWeight: 'bold',
        marginBottom: '15px',
        color: '#10381f',
        fontFamily: 'Red Rose, sans-serif',
        letterSpacing: '1px',
    },
    serviceDescription: {
        fontSize: '1rem',
        marginBottom: '20px',
        color: '#555',
        fontFamily: 'Corbel, sans-serif',
        lineHeight: '1.6',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '15px',
        marginTop: '40px',
        flexWrap: 'wrap',
    },
    ctaButton: {
        padding: '12px 25px',
        fontSize: '1rem',
        color: '#fff',
        backgroundColor: 'black',
        borderRadius: '50px',
        border: 'none',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        fontWeight: 'bold',
        fontFamily: 'Red Rose, sans-serif',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        animation: 'pulse 2s infinite',
    },
    whatsappButton: {
        padding: '12px 25px',
        fontSize: '1rem',
        color: '#fff',
        backgroundColor: 'black',
        borderRadius: '50px',
        border: 'none',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        fontWeight: 'bold',
        fontFamily: 'Red Rose, sans-serif',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    },
    phoneButton: {
        padding: '12px 25px',
        fontSize: '1rem',
        color: '#fff',
        backgroundColor: 'black',
        borderRadius: '50px',
        border: 'none',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        fontWeight: 'bold',
        fontFamily: 'Red Rose, sans-serif',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    },
    '@keyframes pulse': {
        '0%': { transform: 'scale(1)' },
        '50%': { transform: 'scale(1.05)' },
        '100%': { transform: 'scale(1)' },
    },
    alignLeft: {
        flexDirection: 'row',
    },
    alignRight: {
        flexDirection: 'row-reverse',
    },

    /* Media Queries */
    '@media (max-width: 768px)': {
        servicesGrid: {
            flexDirection: 'column',
        },
        serviceBox: {
            flexDirection: 'column',
            width: '100%',
            textAlign: 'center',
        },
        buttonContainer: {
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
        },
        alignLeft: {
            flexDirection: 'column',
        },
        alignRight: {
            flexDirection: 'column',
        },
        heading: {
            fontSize: '2.2rem',
        },
        serviceTitle: {
            fontSize: '1.4rem',
        },
        serviceDescription: {
            fontSize: '0.95rem',
        },
    },
};

export default Services;
