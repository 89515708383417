import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../img/logo.png';
import '../../../assets/fonts/fonts.css';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [activeLang, setActiveLang] = useState(localStorage.getItem('activeLang') || 'en');

  const location = useLocation();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 990);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    i18n.changeLanguage(activeLang);
  }, [activeLang, i18n]);

  const changeLanguage = (lng) => {
    if (activeLang === lng) {

      setActiveLang('en');
      localStorage.setItem('activeLang', 'en');
    } else {

      setActiveLang(lng);
      localStorage.setItem('activeLang', lng);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top" style={styles.navbar}>
      <div className="container-fluid" style={{ position: 'relative' }}>
        <Link className="navbar-brand" to="/" style={styles.brand}>
          <img src={logo} alt="Logo" style={styles.logo} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">

          {isMobile && <div style={styles.horizontalLineTop}></div>}

          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/" style={styles.navLink}>
                {t('navbar.home')}
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/about" style={styles.navLink}>
                {t('navbar.about')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/watches" style={styles.navLink}>
                {t('navbar.watches')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services" style={styles.navLink}>
                {t('navbar.services')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" style={styles.navLink}>
                {t('navbar.contact')}
              </Link>
            </li>

            <li className="nav-item" style={isMobile ? styles.mobileDivider : styles.divider}></li>


            <li className="nav-item d-flex align-items-center">
              <button
                onClick={() => changeLanguage('en')}
                className="btn btn-sm ms-2"
                style={{
                  ...styles.languageButton,
                  backgroundColor: activeLang === 'en' ? '#ffffff' : 'transparent',
                  color: activeLang === 'en' ? '#10381f' : '#ffffff',
                }}
              >
                EN
              </button>
              <button
                onClick={() => changeLanguage('hr')}
                className="btn btn-sm ms-2"
                style={{
                  ...styles.languageButton,
                  backgroundColor: activeLang === 'hr' ? '#ffffff' : 'transparent',
                  color: activeLang === 'hr' ? '#10381f' : '#ffffff',
                }}
              >
                HR
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const styles = {
  navbar: {
    zIndex: 1000,
    backgroundColor: '#10381f',
    padding: '10px 0',
    fontFamily: 'Corbel, sans-serif',
  },
  logo: {
    height: '40px',
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
  },
  navLink: {
    color: '#ffffff',
    margin: '0 10px',
    fontSize: '16px',
    textTransform: 'uppercase',
    fontFamily: 'Corbel, sans-serif',
  },
  languageButton: {
    borderColor: '#ffffff',
    transition: 'background-color 0.3s, color 0.3s',
    fontFamily: 'Corbel, sans-serif',
    fontSize: '12px',
    padding: '5px 10px',
    borderRadius: '20px',
    border: '1px solid #ffffff',
  },
  divider: {
    borderLeft: '1px solid #ffffff',
    height: '40px',
    margin: '0 10px',
  },
  mobileDivider: {
    borderTop: '1px solid #ffffff',
    width: '100%',
    margin: '10px 0',
  },
  horizontalLineTop: {
    borderTop: '1px solid #ffffff',
    width: '100%',
    margin: '10px 0',
  },
};

export default Navbar;
