import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WatchController from '../../controller/WatchController';
import Navbar from './components/Navbar';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Footer from './components/Footer';
import FloatingIcons from './components/FloatingIcons';
import logo from '../img/logogreen.png';
import WatchItem from './WatchItem';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

const DetailsSpacer = () => <div style={styles.spacer}></div>;

const WatchDetails = () => {
  const { id } = useParams();
  const [watch, setWatch] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [otherWatches, setOtherWatches] = useState([]);
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const watches = await WatchController.fetchWatches();
      const foundWatch = watches.find((w) => w.id === id);
      setWatch(foundWatch);
      if (foundWatch) {
        setMainImage(foundWatch.imageUrls[0]);
        setCurrentIndex(0);
        
        // Preload images
        foundWatch.imageUrls.forEach(url => {
          const img = new Image();
          img.src = url; // Preload image
        });
      }

      const otherWatches = watches.filter((w) => w.id !== id);
      setOtherWatches(otherWatches);
    };

    fetchData();

    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, [id]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleThumbnailClick = (url, index) => {
    setMainImage(url);
    setCurrentIndex(index);
  };

  if (!watch) {
    return;
  }

  // Slick settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <DetailsSpacer />
      <div style={styles.container}>
        <div style={styles.row}>
          <div style={styles.column}>
            <Zoom>
              <div style={{ position: 'relative' }}>
                <img
                  src={mainImage}
                  alt={watch.name}
                  style={getResponsiveImageStyles(window.innerWidth)}
                />
                <img src={logo} alt="logo" style={styles.logoOverlay} />
              </div>
            </Zoom>

            <div style={styles.gallery}>
              {watch.imageUrls.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`watch-${index}`}
                  style={mainImage === url ? { ...styles.thumbnail, ...styles.selectedThumbnail } : styles.thumbnail}
                  onClick={() => handleThumbnailClick(url, index)}
                />
              ))}
            </div>
          </div>

          <div style={styles.column}>
            <h1>{watch.name}</h1>
            <h5 style={styles.textMuted}>Ref. {watch.refNumber}</h5>
            <h3>{watch.price.toLocaleString()}</h3>
            <p style={styles.inStock}>{watch.inStock ? 'AVAILABLE' : 'OUT OF STOCK'}</p>

            <div style={styles.info}>
              <div style={{ ...styles.row, ...styles.greenBackground }}>
                <div style={styles.label}>
                  <strong>{t('watch_details.brand')}:</strong>
                </div>
                <div>{watch.brand}</div>
              </div>
              <div style={styles.row}>
                <div style={styles.label}>
                  <strong>{t('watch_details.reference_number')}:</strong>
                </div>
                <div>{watch.refNumber}</div>
              </div>
              <div style={{ ...styles.row, ...styles.greenBackground }}>
                <div style={styles.label}>
                  <strong>{t('watch_details.year')}:</strong>
                </div>
                <div>{watch.year}</div>
              </div>
              <div style={styles.row}>
                <div style={styles.label}>
                  <strong>{t('watch_details.state')}:</strong>
                </div>
                <div>{watch.state}</div>
              </div>
              <div style={{ ...styles.row, ...styles.greenBackground }}>
                <div style={styles.label}>
                  <strong>{t('watch_details.case_size')}:</strong>
                </div>
                <div>{watch.caseSize}</div>
              </div>
              <div style={styles.row}>
                <div style={styles.label}>
                  <strong>{t('watch_details.price')}:</strong>
                </div>
                <div>{watch.price.toLocaleString()}</div>
              </div>
              <div style={{ ...styles.row, ...styles.greenBackground }}>
                <div style={styles.label}>
                  <strong>{t('watch_details.in_stock')}:</strong>
                </div>
                <div>{watch.inStock ? 'Yes' : 'No'}</div>
              </div>
            </div>
          </div>
        </div>

        <div style={styles.otherWatchesContainer}>
          <h2 style={styles.collectionTitle}>{t('watch_details.other_watches')}</h2>
          <Slider {...settings}>
            {otherWatches.map((otherWatch) => (
              <div key={otherWatch.id}>
                <WatchItem watch={otherWatch} />
              </div>
            ))}
          </Slider>
        </div>

        <DetailsSpacer />
      </div>
      <FloatingIcons />
      {isVisible && (
        <button onClick={scrollToTop} style={styles.backToTopButton}>
          ↑
        </button>
      )}

      <Footer />
    </>
  );
};


const getResponsiveImageStyles = (width) => {
  if (width <= 480) {
    return {
      width: '100%',
      padding: '5px',
      border: '1px solid #ddd',
    };
  } else if (width <= 768) {
    return {
      width: '80%',
      padding: '10px',
      border: '1px solid #ddd',
    };
  } else {
    return {
      width: '100%',
      padding: '10px',
      border: '1px solid #ddd',
    };
  }
};

const styles = {
  spacer: {
    height: '100px',
  },
  container: {
    marginTop: '20px',
    padding: '0 15px',
  },
  collectionTitle: {
    backgroundColor: '#10381f',
    color: '#ffffff',
    padding: '15px 30px',
    fontSize: '1.5rem',
    textAlign: 'right',
    marginBottom: '20px',
    marginTop: '0',
    width: '300px',
    fontFamily: 'Red Rose, sans-serif',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '10px 0',
    borderBottom: '1px solid #ccc',
    fontFamily: 'Corbel, sans-serif',
  },
  column: {
    flex: '1',
    padding: '10px',
    minWidth: '300px',
  },
  backToTopButton: {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    padding: '10px 20px',
    backgroundColor: 'black',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    fontSize: '1rem',
    cursor: 'pointer',
    fontFamily: 'Red Rose, sans-serif',
    transition: 'opacity 0.3s',
    zIndex: 1000,
  },  
  gallery: {
    display: 'flex',
    marginTop: '10px',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  thumbnail: {
    width: '80px',
    height: 'auto',
    marginRight: '5px',
    cursor: 'pointer',
    border: '1px solid #ddd',
  },
  selectedThumbnail: {
    border: '2px solid #007bff',
  },
  textMuted: {
    color: '#6c757d',
  },
  inStock: {
    color: '#28a745',
  },
  info: {
    marginTop: '20px',
    border: '1px solid #ddd',
    borderRadius: '5px',
  },
  label: {
    width: '150px',
    fontWeight: 'bold',
  },
  greenBackground: {
    backgroundColor: '#aebca8',
  },
  logoOverlay: {
    position: 'absolute',
    bottom: '30px',
    right: '20px',
    width: '100px',
  },
  otherWatchesContainer: {
    marginTop: '40px',
  },
};

export default WatchDetails;
