import React from 'react';
import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import timelessImage from '../../img/timeless.png';
import watches from '../../img/watches.jpg';
import rolli from '../../img/rolli.jpg';
import ap from '../../img/ap.jpg';

const getResponsiveStyles = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth <= 576) {
    return {
      img: {
        maxWidth: '100%',
        maxHeight: '100%',
      },
      paragraph: {
        fontSize: '3vw',
      },
      button: {
        fontSize: '3vw',
      },
    };
  } else if (screenWidth <= 768) {
    return {
      img: {
        maxWidth: '100%',
        maxHeight: '100%',
      },
      paragraph: {
        fontSize: '4vw',
      },
      button: {
        fontSize: '4vw',
      },
    };
  }

  return {
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    paragraph: {
      fontSize: '2vw',
    },
    button: {
      fontSize: '1.5vw',
      padding: '0.75rem 1.5rem',
    },
  };
};

const HeroSection = () => {
  const { t } = useTranslation();
  const responsiveStyles = getResponsiveStyles();

  const styles = {
    heroSection: {
      position: 'relative',
      height: '80vh',
      width: '100%',
      overflow: 'hidden',
    },
    carouselImage: {
      height: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      filter: 'brightness(0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

    },
    imageShadowOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1,
      animation: 'fadeIn 1s ease-in-out', // Slowed down from 1.5s to 3s
    },
    
    logo: {
      ...responsiveStyles.img,
      objectFit: 'contain',
      zIndex: 3,
      position: 'absolute',
      top: '60%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      animation: 'fadeIn 2s ease-out 2s forwards', // Extended duration + increased delay
    },
    
    timelessLogo: {
      ...responsiveStyles.img,
      objectFit: 'contain',
      zIndex: 3,
      position: 'absolute',
      top: '30%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      animation: 'fadeIn 2s ease-out 1s forwards', // Further delayed fade-in
    },
    
    caption: {
      position: 'absolute',
      top: '60%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      color: '#fff',
      textShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
      zIndex: 2,
      opacity: 0,
      animation: 'fadeIn 2s ease-out 1s forwards', // Smoothest fade-in with longer delay
    },
    
    paragraph: {
      ...responsiveStyles.paragraph,
      marginBottom: '2rem',
      fontFamily: 'Red Rose, sans-serif',
    },
    
    button: {
      ...responsiveStyles.button,
      backgroundColor: '#10381f',
      border: 'none',
      color: '#ffffff',
      borderRadius: '5px',
      fontFamily: 'Red Rose, sans-serif',
      zIndex: 2,
      opacity: 0,
      animation: 'fadeIn 2s ease-out 1s forwards', // Slowed down button animation
    },
    
    paragraph: {
      ...responsiveStyles.paragraph,
      marginBottom: '2rem',
      fontFamily: 'Red Rose, sans-serif',
    },
    
  };

  return (
    <div style={styles.heroSection}>
      <Carousel controls={false} indicators={false} fade={true} interval={5000}>
      <Carousel.Item>
          < div style={{ ...styles.carouselImage, backgroundImage: `url(${rolli})` }}>
            <div style={styles.imageShadowOverlay}></div>
            <img src={timelessImage} alt="Timeless Logo" style={styles.timelessLogo} />
            <Carousel.Caption style={styles.caption}>
              <p style={styles.paragraph}>{t('hero.luxury_watches')}</p>

            </Carousel.Caption>
          </div>
        </Carousel.Item >

        <Carousel.Item>
          <div style={{ ...styles.carouselImage, backgroundImage: `url(${ap})` }}>
            <div style={styles.imageShadowOverlay}></div>
            <img src={timelessImage} alt="Timeless Logo" style={styles.logo} />
          </div>
        </Carousel.Item>

        {/* <Carousel.Item>
          <div style={{ ...styles.carouselImage, backgroundImage: `url(${watches})` }}>
            <div style={styles.imageShadowOverlay}></div>
            <img src={timelessImage} alt="Timeless Logo" style={styles.timelessLogo} />
            <Carousel.Caption style={styles.caption}>
              <p style={styles.paragraph}>{t('hero.experience_art')}</p>

            </Carousel.Caption>
          </div>
        </Carousel.Item> */}
        {/* <a href="watches" className="btn btn-primary btn-lg" style={styles.button}>
                {t('hero.browse_collection')}
              </a> */}
       
      </Carousel >
    </div >
  );
};

{/* <a href="watches" className="btn btn-primary btn-lg" style={styles.button}>
                {t('hero.view_more')}
              </a> */}
const stylesGlobal = document.createElement('style');
stylesGlobal.innerHTML = `
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;
document.head.appendChild(stylesGlobal);

export default HeroSection;
